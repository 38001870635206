import Form from 'react-bootstrap/Form';
import { Field, ErrorMessage } from 'formik';
import styles from '../assets/styles/containers/RuleModal.module.scss';
import { concatArray, parseArray } from '../utils/formUtils';
import CampaignActions from '../data/CampaignActions.json';
import AdGroupActions from '../data/AdGroupActions.json';
import PlacementBidActions from '../data/PlacementBidActions.json';
import OperationTypes from '../data/OperationTypes.json';
import CreativesActions from '../data/CreativesActions.json';
import AdvertiseFSNActions from '../data/AdvertiseFSNActions.json';
import rulesData from '../data/rules.json';

const SelectField = ({
  name,
  placeholder,
  values,
  disabled,
  fieldOptions,
  options,
  setFieldOptions,
  setValues,
  setFieldValue,
  resetForm,
}) => {
  // Don't delete this
  const previousHandleApplyRuleChange = async e => {
    setFieldOptions({
      ...fieldOptions,
      actions: [],
    });
    const [optionId, optionVal] = e.target.value.split(',');
    setValues({ ...values, applyRule: e.target.value, actionType: '' });
    const resp = await fetch(`${process.env.REACT_APP_ACTIONS}/${optionId}`);
    const data = await resp.json();
    setFieldOptions({
      ...fieldOptions,
      actions: parseArray(data.result),
    });
  };

  const currentHandleApplyRuleChange = value => {
    const applyRuleVal = value.split(',')[1];
    if (applyRuleVal === 'Campaign') {
      setFieldOptions({
        ...fieldOptions,
        actions: CampaignActions,
      });
    } else if (applyRuleVal === 'AdGroup') {
      setFieldOptions({
        ...fieldOptions,
        actions: AdGroupActions,
      });
    } else if (applyRuleVal === 'Placement Bid') {
      setFieldOptions({
        ...fieldOptions,
        actions: PlacementBidActions,
        updateOperationTypes: OperationTypes,
      });
    } else if (applyRuleVal === 'Creatives') {
      setFieldOptions({
        ...fieldOptions,
        actions: CreativesActions,
      });
    } else if (applyRuleVal === 'AdvertiseFSN') {
      setFieldOptions({
        ...fieldOptions,
        actions: AdvertiseFSNActions,
      });
    } else {
      setFieldOptions({
        ...fieldOptions,
        actions: [],
      });
    }
  };

  const onOptionChange = async (e, form) => {
    form.handleChange(e);
    if (name === 'dataPeriod') {
      resetForm();
      setFieldValue(name, e.target.value);
      setFieldValue('timeRange', '');
      if (e.target.value === 'Realtime Data') {
        setFieldOptions({
          ...fieldOptions,
          rules: rulesData.result.filter(ruleItem => {
            const realTimeRuleKeys = ['campaign', 'adGroup', 'AdvertiseFSN'];
            return (
              ruleItem.key === realTimeRuleKeys[0] ||
              ruleItem.key === realTimeRuleKeys[1] ||
              ruleItem.key === realTimeRuleKeys[2]
            );
          }),
        });
      } else {
        setFieldOptions({
          ...fieldOptions,
          rules: rulesData.result,
        });
      }
    }
    if (name === 'applyRule') {
      setFieldOptions({ ...fieldOptions, actions: [] });
      setValues({
        ...values,
        applyRule: e.target.value,
        actionType: '',
        campaignBudget: '',
        bidChange: '',
        bidValue: '',
      });
      currentHandleApplyRuleChange(e.target.value);
    }
    if (name === 'actionType') {
      setValues({
        ...values,
        actionType: e.target.value,
        campaignBudget: '',
        bidChange: '',
        bidValue: '',
      });

      // Don't delete the below commented statements

      // if (e.target.value !== 'Budget Increase' || e.target.value !== 'Set Budget Limit') {
      //   setValues({
      //     ...values,
      //     actionType: e.target.value,
      //     campaignBudget: '',
      //   });
      // }
    }
    if (name === 'bidChange') {
      setValues({
        ...values,
        bidChange: e.target.value,
        bidValue: '',
      });
    }
    if (name.includes('conditions')) {
      const dynamicFieldIndex = name.split('.')[1];
      if (name === `conditions.${dynamicFieldIndex}.condition`) {
        const conditionValue = e.target.value;
        // Update the condition in the form state
        setFieldValue(name, conditionValue);
        setFieldValue(`conditions.${dynamicFieldIndex}.from_value.field_1`, '');
        setFieldValue(`conditions.${dynamicFieldIndex}.from_value.field_2`, '');
        setFieldValue(`conditions.${dynamicFieldIndex}.from_value.field_3`, '');

        // Don't delete the below commented statements

        // Update other fields based on the condition
        // if (conditionValue === 'Is between' || conditionValue === 'Is not between') {
        //   setFieldValue(`conditions.${dynamicFieldIndex}.from_value.field_2`, '');
        //   setFieldValue(`conditions.${dynamicFieldIndex}.from_value.field_3`, '');
        // } else {
        //   setFieldValue(`conditions.${dynamicFieldIndex}.from_value.field_1`, '');
        // }
      }
    }
  };

  const calOptionValue = optionObj => {
    if (name === 'applyRule') {
      return `${optionObj['_id']},${optionObj.name}`;
    } else if (name === 'actionType') {
      // Don't delete below commented statement
      // return `${optionObj.key},${optionObj.type_name},${optionObj.name}`;
      return optionObj.name;
    } else {
      return optionObj.name;
    }
  };

  return (
    <>
      <Field as="select" name={name}>
        {({ field, form }) => (
          <Form.Select
            {...field}
            className={styles.formInput}
            onChange={e => onOptionChange(e, form)}
            disabled={disabled}
            style={{ fontSize: '0.9rem' }}
          >
            <option value="" disabled selected>
              {placeholder}
            </option>
            {options.map(option => (
              <option key={option.key} value={calOptionValue(option)}>
                {option.name}
              </option>
            ))}
          </Form.Select>
        )}
      </Field>
      <ErrorMessage
        name={name}
        component={props => (
          <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
            {props.children}
          </Form.Control.Feedback>
        )}
      />
    </>
  );
};

SelectField.defaultProps = {
  name: '',
  disabled: false,
  setFieldValue: () => {},
};

export default SelectField;
