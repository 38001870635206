import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from '../../assets/styles/containers/RuleModal.module.scss';
import RuleModalOpenBtn from './RuleModalOpenBtn';
import RuleModalForm from './RuleModalForm';
import conditionsData from '../../data/conditions.json';
import periods from '../../data/periods.json';
import rulesData from '../../data/rules.json';
import timeRangesData from '../../data/timeRanges.json';
import { transformedConditions } from '../../utils/formUtils';
import LogTable from '../LogTable';
import RuleTable from '../RuleTable';
import RuleFormAPI from '../../api/RuleFormAPI';

const RuleModal = () => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [fieldOptions, setFieldOptions] = useState({
    periods: [],
    rules: [],
    actions: [],
    updateOperationTypes: [],
    metrics: [],
    conditions: [],
    relations: [],
    timeRanges: [],
  });

  const notify = () => toast('Rule Successfully Created!');

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    try {
      // const [rulesObj, conditionObj, timeRangesObj] = await Promise.all([
      //   fetch(process.env.REACT_APP_RULES).then(res => res.json()),
      //   fetch(process.env.REACT_APP_CONDITIONS).then(res => res.json()),
      //   fetch(process.env.REACT_APP_SCHEDULES).then(res => res.json()),
      // ]);
      setFieldOptions({
        ...fieldOptions,
        periods,
        rules: rulesData.result,
        metrics: conditionsData.result.metrics,
        conditions: conditionsData.result.conditions,
        relations: conditionsData.result.relations,
        timeRanges: timeRangesData.result.timeRanges,
      });
    } catch (e) {
      // console.log('Error: ', e);
    }
    setShow(true);
  };

  const addRemoveConditions = (e, values, setValues, action, index = -1) => {
    e.preventDefault();
    const conditions = [...values.conditions];
    if (action === 'create') {
      if (index === -1) {
        conditions.push({
          metric: '',
          condition: '',
          from_value: { field_1: '' },
          relation: 'AND',
        });
      } else {
        // index + 1
        conditions.splice(index + 1, 0, {
          metric: '',
          condition: '',
          from_value: { field_1: '' },
          relation: 'OR',
        });
      }
    } else {
      conditions.splice(index, 1);
    }
    setValues({ ...values, conditions });
  };

  const onSubmit = async ({
    dataPeriod,
    adsCategory,
    ruleName,
    applyRule,
    actionType,
    campaignBudget,
    bidChange,
    bidValue,
    conditions,
    timeRange,
    schedule,
  }) => {
    const [applyRuleId, applyRuleTo] = applyRule.split(',');
    // Don't delete below commented statement
    // const [key, type_name, name] = actionType.split(',');
    const modifiedConditions = conditions.map(field => {
      if (typeof field.from_value.field_1 === 'string') {
        const tempField = field.from_value.field_3;
        return { ...field, from_value: field.from_value.field_2, to: tempField };
      } else {
        return { ...field, from_value: field.from_value.field_1 };
      }
    });

    const payload = {
      dataPeriod,
      adsCategory,
      ruleName,
      applyRuleTo,
      action: {
        name: actionType,
        campaignBudget: campaignBudget ? campaignBudget : null,
        bidOperation: bidChange || null,
        bidValue: bidValue || null,
      },
      // Don't delete below commented key-value pair
      // action: { type_name, details: [{ name, key }] }
      conditions: transformedConditions(modifiedConditions),
      timeRange,
    };
    // console.log('payload', JSON.stringify(payload, null, 4));
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(payload, null, 4));
    try {
      await RuleFormAPI.post('/rules', payload);
      // alert(JSON.stringify(payload, null, 4));
      notify();
      setShow(false);
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    setError(false);
  }, [show]);

  // console.log('fieldOptions', fieldOptions);

  return (
    <>
      <div className={styles.primaryContainer}>
        <div className={styles.primaryHeader}>
          <div className="createRuleBtnContainer">
            <RuleModalOpenBtn title="Apply Rules" handleShow={handleShow} />
          </div>
          {/* Do not Delete the above commented JSX */}
          {/* <div className={styles.logTablePrimaryContainer}>
            <RuleTable />
          </div> */}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className={styles.header} closeButton>
          <Modal.Title className={styles.modalTitle}>Custom rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RuleModalForm
            fieldOptions={fieldOptions}
            error={error}
            addRemoveConditions={addRemoveConditions}
            setFieldOptions={setFieldOptions}
            handleClose={handleClose}
            onSubmit={onSubmit}
          />
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default RuleModal;
