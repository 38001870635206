import Form from 'react-bootstrap/Form';
import { Field, ErrorMessage } from 'formik';
import styles from '../assets/styles/containers/RuleModal.module.scss';

const NumberField = ({ name, placeholder, ...rest }) => {
  return (
    <>
      <Field name={name}>
        {({ field, form }) => (
          <Form.Control
            {...field}
            className={styles.formInput}
            type="number"
            min={0}
            placeholder={placeholder}
            onChange={e => form.handleChange(e)}
            {...rest}
          />
        )}
      </Field>
      <ErrorMessage
        name={name}
        component={props => (
          <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
            {props.children}
          </Form.Control.Feedback>
        )}
      />
    </>
  );
};

NumberField.defaultProps = {
  placeholder: '',
};

export default NumberField;
