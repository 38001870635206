import Form from 'react-bootstrap/Form';
import { Field, ErrorMessage } from 'formik';
import styles from '../assets/styles/containers/RuleModal.module.scss';

const TextField = ({ name }) => {
  return (
    <>
      <Field name={name}>
        {({ field, form }) => (
          <Form.Control
            {...field}
            className={styles.formInput}
            type="text"
            placeholder=""
            onChange={e => form.handleChange(e)}
          />
        )}
      </Field>
      <ErrorMessage
        name={name}
        component={props => (
          <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
            {props.children}
          </Form.Control.Feedback>
        )}
      />
    </>
  );
};

export default TextField;
