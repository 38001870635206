import React from 'react';
import Button from 'react-bootstrap/Button';

export default function RuleModalOpenBtn({ title, handleShow }) {
  return (
    <Button variant="primary" onClick={handleShow}>
      {title}
    </Button>
  );
}
