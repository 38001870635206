import React from 'react';
import { Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import RuleModal from './containers/RuleModal';
import RuleTable from './containers/RuleTable';
import LogTable from './containers/LogTable';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<RuleModal />} />
      <Route path="/rule-table" element={<RuleTable />} />
      <Route path="/log-table" element={<LogTable />} />
    </Routes>
  );
};

export default App;
