import Form from 'react-bootstrap/Form';
import { Field, ErrorMessage } from 'formik';

const CheckBoxField = ({ checkBoxesArr, values }) => {
  return (
    <>
      <Field name="adsCategory">
        {({ field }) =>
          checkBoxesArr.map(({ id, label, value }) => (
            <Form.Check
              inline
              label={label}
              type="radio"
              id={id}
              {...field}
              value={value}
              checked={value === values.adsCategory}
            />
          ))
        }
      </Field>
      <ErrorMessage
        name={'adsCategory'}
        component={props => (
          <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
            {props.children}
          </Form.Control.Feedback>
        )}
      />
    </>
  );
};

export default CheckBoxField;
