import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  primaryField: Yup.string().required('Primary field is required'),
  conditionalField: Yup.string().when('primaryField', {
    is: value => value === 'specificValue',
    then: () =>
      Yup.string().required('This field is required when primaryField has a specific value'),
  }),
});

const MyForm = () => {
  const initialValues = {
    primaryField: '',
    conditionalField: '',
  };

  const handleSubmit = values => {
    // console.log(values);
    // Handle form submission logic here
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values }) => (
        <Form onSubmit={handleSubmit}>
          <div>
            <label>Primary Field</label>
            <Field name="primaryField" as="select">
              <option value="">Select an option</option>
              <option value="specificValue">Specific Value</option>
              {/* Add other options as needed */}
            </Field>
          </div>

          {values.primaryField === 'specificValue' && (
            <div>
              <label>Conditional Field</label>
              <Field name="conditionalField" type="text" />
              <ErrorMessage name="conditionalField" component="div" />
            </div>
          )}

          <button type="submit">Submit</button>
        </Form>
      )}
    </Formik>
  );
};

export default MyForm;
