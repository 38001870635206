import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FieldArray } from 'formik';

import styles from '../../assets/styles/containers/RuleModal.module.scss';
import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';
import NumberField from '../../components/NumberField';

const metricArray = ['Metric_1', 'Metric_2', 'Metric_3', 'Metric_4', 'Metric_5'];
const conditionArray = ['Condition_1', 'Condition_2', 'Condition_3', 'Condition_4', 'Condition_5'];

export default function DynamicField({
  values,
  fieldOptions,
  errors,
  touched,
  setValues,
  setFieldValue,
  addRemoveConditions,
  setFieldOptions,
}) {
  return (
    <FieldArray name="conditions">
      {() =>
        values.conditions.map((item, i, arr) => {
          return (
            <>
              <div className={styles.conditions}>
                <Row className="justify-content-around">
                  <Col sm={12} md={3} lg={3}>
                    <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                      <SelectField
                        name={`conditions.${i}.metric`}
                        placeholder="Select Metric"
                        values={values}
                        fieldOptions={fieldOptions}
                        options={fieldOptions.metrics}
                        setFieldOptions={setFieldOptions}
                        setValues={setValues}
                        setFieldValue={setFieldValue}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={3} lg={3}>
                    <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                      <SelectField
                        name={`conditions.${i}.condition`}
                        values={values}
                        placeholder="Select Condition"
                        fieldOptions={fieldOptions}
                        options={fieldOptions.conditions}
                        setFieldOptions={setFieldOptions}
                        setValues={setValues}
                        setFieldValue={setFieldValue}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    {values.conditions[i].condition === 'Is between' ||
                    values.conditions[i].condition === 'Is not between' ? (
                      <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
                        <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
                          <NumberField
                            name={`conditions.${i}.from_value.field_2`}
                            placeholder="From"
                            step="any"
                          />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
                          <NumberField
                            name={`conditions.${i}.from_value.field_3`}
                            placeholder="To"
                            step="any"
                          />
                        </Form.Group>
                      </Form.Group>
                    ) : (
                      <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                        <NumberField name={`conditions.${i}.from_value.field_1`} step="any" />
                      </Form.Group>
                    )}
                  </Col>
                  {/* <Col md={1} lg={1}>
                  <Button variant="secondary">Add</Button>
                </Col> */}
                  <Col md={1} lg={1}>
                    <Button
                      variant="secondary"
                      onClick={e => addRemoveConditions(e, values, setValues, 'delete', i)}
                    >
                      X
                    </Button>
                  </Col>
                </Row>
              </div>
              {/* {i === arr.length - 1 ? null : (
                <div>
                  <Row>
                    <div className="w-25">
                      <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                        <SelectField
                          name={`conditions.${i}.relation`}
                          values={values}
                          placeholder="Select Relation"
                          fieldOptions={fieldOptions}
                          options={fieldOptions.relations}
                          setFieldOptions={setFieldOptions}
                          setValues={setValues}
                          setFieldValue={setFieldValue}
                        />
                      </Form.Group>
                    </div>
                  </Row>
                </div>
              )} */}
            </>
          );
        })
      }
    </FieldArray>
  );
}
