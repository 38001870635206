import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import DynamicForm from './DemoDynamicForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import RuleModal from './containers/RuleModal';
import App from './App';
import RuleTable from './containers/RuleTable';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
