import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { FaUndoAlt, FaRedoAlt, FaPlay, FaPause } from 'react-icons/fa';
import { BiLoaderCircle } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { CiPause1, CiPlay1 } from 'react-icons/ci';
import { ImSpinner10 } from 'react-icons/im';
import AlertDialogBox from '../../components/AlertDialogBox';
import styles from '../../assets/styles/containers/RuleTable.module.scss';

const RuleTable = () => {
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [undoLoading, setUndoLoading] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [show, setShow] = useState(false);
  const [dialogBoxData, setDialogBoxData] = useState({
    id: '',
    actionType: '',
    heading: '',
    body: '',
    submitBtnName: '',
  });

  const printPassRuleDeleteMsg = () => toast('Rule Successfully Deleted!');
  const printFailedRuleDeleteMsg = () => toast('Something went wrong!');

  const fetchRules = async () => {
    axios
      .get(`${process.env.REACT_APP_RULES_API}/getslackrules`, {
        headers: {
          'ngrok-skip-browser-warning': 'skip',
        },
      })
      .then(resp => {
        setLoading(false);
        setUndoLoading(false);
        setSelectedRule(null);
        setRules(resp.data.slackRules);
      })
      .catch(err => setLoading(false));
  };
  const handleClose = () => setShow(false);
  const handleShow = (id, actionType) => {
    if (actionType === 'delete') {
      setDialogBoxData({
        ...dialogBoxData,
        id,
        actionType,
        heading: 'Delete',
        body: '<div><div>Do you want to delete the rule?</div><br /><div class="alert alert-warning" role="alert">Deleting the following rule will only delete the rule <b>NOT</b> change the bids to default</div></div>',
        submitBtnName: 'Delete',
      });
    }
    setShow(true);
  };
  const handleUndoRedo = async (id, actionType) => {
    setUndoLoading(true);
    setSelectedRule(id);
    axios
      .post(
        `${process.env.REACT_APP_RULES_API}/aborted/${id}/?undo=${
          actionType === 'undo' ? true : false
        }`
      )
      .then(() => {
        fetchRules();
      });
  };
  const handleResumePause = (id, isAbortedVal) => {
    axios
      .post(`${process.env.REACT_APP_RULES_API}/aborted/${id}/?undo=${isAbortedVal}`)
      .then(() => {
        fetchRules();
      });
  };
  const handleSubmit = async (id, actionType) => {
    if (actionType === 'delete') {
      axios
        .delete(`${process.env.REACT_APP_RULES_API}/rules/${id}`)
        .then(() => {
          printPassRuleDeleteMsg();
          setShow(false);
          setDialogBoxData({
            ...dialogBoxData,
            id: '',
            actionType: '',
            heading: '',
            body: '',
            submitBtnName: '',
          });
          fetchRules();
        })
        .catch(err => {
          printFailedRuleDeleteMsg();
        });
    }
  };

  useEffect(() => {
    fetchRules();
  }, []);

  return (
    <>
      <Table hover bordered responsive>
        <thead>
          <tr>
            <th>RuleName</th>
            <th>Apply Rule To</th>
            <th>Actions</th>
            <th>Conditions</th>
            <th>Operations</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <span>Loading...</span>
          ) : rules?.length ? (
            rules.map(ruleItem => (
              <tr key={ruleItem._id}>
                <td>{ruleItem.ruleName}</td>
                <td>{ruleItem.applyRuleTo}</td>
                <td>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Campaign Budget</th>
                        <th>Bid Operation</th>
                        <th>Bid Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{ruleItem.action?.name || 'Notify'}</td>
                        <td>{ruleItem.action?.campaignBudget || '-'}</td>
                        <td>{ruleItem.action?.bidOperation || '-'}</td>
                        <td>{ruleItem.action?.bidValue || '-'}</td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Metrics</th>
                        <th>Condition</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    {ruleItem.conditions?.length ? (
                      <tbody>
                        {ruleItem.conditions.flatMap((conditionItem, index) =>
                          conditionItem.check ? (
                            []
                          ) : (
                            <tr key={`condition-${ruleItem._id}-${index}`}>
                              <td>{conditionItem.metric}</td>
                              <td>{conditionItem.condition}</td>
                              <td>
                                {!conditionItem.to ? (
                                  conditionItem.from_value
                                ) : (
                                  <Table bordered>
                                    <thead>
                                      <tr>
                                        <th>From</th>
                                        <th>To</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>{conditionItem.from_value}</td>
                                        <td>{conditionItem.to}</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="text-center" colSpan={3}>
                            No conditions selected
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </td>
                <td>
                  <div className="d-flex justify-content-around">
                    <Button
                      style={{
                        backgroundColor: 'transparent', // Set background color to transparent
                        borderColor: 'transparent', // Set border color to transparent
                      }}
                      onClick={() => handleShow(ruleItem._id, 'delete')}
                    >
                      <MdDelete color="red" size={20} />
                    </Button>
                    {/* {undoLoading && selectedRule === ruleItem._id ? (
                      <div className={styles.loader_container}>
                        <ImSpinner10 color="black" size={20} />
                      </div>
                    ) : (
                      <Button
                        style={{
                          backgroundColor: 'transparent', // Set background color to transparent
                          borderColor: 'transparent', // Set border color to transparent
                        }}
                        disabled={ruleItem.isAborted}
                        onClick={() => handleUndoRedo(ruleItem._id, 'undo')}
                      >
                        <FaUndoAlt color="blue" size={20} />
                      </Button>
                    )} */}
                    {/* <Button
                      style={{
                        backgroundColor: 'transparent', // Set background color to transparent
                        borderColor: 'transparent', // Set border color to transparent
                      }}
                      disabled={!ruleItem.isAborted}
                      onClick={() => handleUndoRedo(ruleItem._id, 'redo')}
                    >
                      <FaRedoAlt color="green" size={20} />
                    </Button> */}
                    <Button
                      style={{
                        backgroundColor: 'transparent', // Set background color to transparent
                        borderColor: 'transparent', // Set border color to transparent
                      }}
                      onClick={() => handleResumePause(ruleItem._id, !ruleItem.isAborted)}
                    >
                      {loading ? (
                        <BiLoaderCircle />
                      ) : ruleItem.isAborted ? (
                        <FaPlay color="green" size={20} />
                      ) : (
                        <FaPause color="orange" size={20} />
                      )}
                    </Button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <div>No records found</div>
          )}
        </tbody>
      </Table>
      <AlertDialogBox
        show={show}
        dialogBoxData={dialogBoxData}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
      <ToastContainer />
    </>
  );
};

export default RuleTable;
