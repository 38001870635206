import React, { Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { FaInfoCircle } from 'react-icons/fa';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import { LuPlus } from 'react-icons/lu';

import styles from '../../assets/styles/containers/RuleModal.module.scss';
import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';
import DynamicField from './DynamicField';
import { initialValues, validationSchema } from '../../utils/formUtils';
import NumberField from '../../components/NumberField';
import CheckBoxField from '../../components/CheckBoxGroupField';
import CampaignOptions from '../../data/CampaignOptions.json';

const ruleArray = ['Rule_1', 'Rule_2', 'Rule_3', 'Rule_4', 'Rule_5'];
const actionArray = [
  { key: 'Action_1', name: 'Action_1' },
  { key: 'Action_2', name: 'Action_2' },
  { key: 'Action_3', name: 'Action_3' },
  { key: 'Action_4', name: 'Action_4' },
  { key: 'Action_5', name: 'Action_5' },
];
const timeRangeArray = ['timeRange_1', 'timeRange_2', 'timeRange_3', 'timeRange_4', 'timeRange_5'];

export default function RuleModalForm({
  fieldOptions,
  error,
  addRemoveConditions,
  setFieldOptions,
  handleClose,
  onSubmit,
}) {
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, values, touched, setValues, setFieldValue, resetForm }) => (
        <FormikForm>
          <div>
            <section className={styles.modalMain}>
              <p className={styles.ruleHeading}>
                Automatically update campaigns, ad sets or ads in bulk by creating automated rules.
                Learn more
              </p>
              {/*       Data Category Feild       */}
              <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                <Form.Label className={styles.formLabel}>Data Category</Form.Label>
                <SelectField
                  name="dataPeriod"
                  placeholder="Click here to select data period"
                  values={values}
                  fieldOptions={fieldOptions}
                  options={fieldOptions.periods}
                  setFieldOptions={setFieldOptions}
                  setValues={setValues}
                  setFieldValue={setFieldValue}
                  resetForm={resetForm}
                />
              </Form.Group>

              {/*       Rule Name Feild       */}
              <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                <Form.Label className={styles.formLabel}>
                  Rule Name (e.g. efficient-campaign)
                </Form.Label>
                <TextField name={'ruleName'} />
              </Form.Group>

              {/*       Apply Rule Feild       */}
              <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                <Form.Label className={styles.formLabel}>Apply rule to</Form.Label>
                <SelectField
                  name="applyRule"
                  placeholder="Click here to select apply rule"
                  values={values}
                  fieldOptions={fieldOptions}
                  options={fieldOptions.rules}
                  setFieldOptions={setFieldOptions}
                  setValues={setValues}
                  setFieldValue={setFieldValue}
                  resetForm={resetForm}
                />
              </Form.Group>

              {/*       AdsCategory Feild       */}
              {values.applyRule?.split(',')[1] !== 'Creatives' &&
              values.applyRule?.split(',')[1] !== 'AdvertiseFSN' ? (
                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                  <Form.Label className={styles.formLabel}>Campaign Category</Form.Label>
                  <div key={`default-radio`} className="mb-3">
                    <CheckBoxField
                      checkBoxesArr={CampaignOptions}
                      values={values}
                      fieldOptions={fieldOptions}
                      setFieldOptions={setFieldOptions}
                      setValues={setValues}
                    />
                  </div>
                </Form.Group>
              ) : null}

              {/*       Action Feild       */}
              <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                <Form.Label className={styles.formLabel}>Action</Form.Label>
                <SelectField
                  name="actionType"
                  placeholder="Click here to select action type"
                  values={values}
                  fieldOptions={fieldOptions}
                  options={fieldOptions.actions}
                  disabled={
                    !values.applyRule ||
                    values.applyRule?.split(',')[1] === 'SearchTerm' ||
                    values.applyRule?.split(',')[1] === 'Targeting'
                  }
                  setFieldOptions={setFieldOptions}
                  setValues={setValues}
                  setFieldValue={setFieldValue}
                  resetForm={resetForm}
                />
              </Form.Group>

              {/*       CampaignBudget Feild       */}
              {values.actionType === 'Budget Increase' ||
              values.actionType === 'Set Budget Limit' ? (
                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                  <Form.Label className={styles.formLabel}>Budget (Min Budget 100)</Form.Label>
                  <NumberField name={`campaignBudget`} />
                </Form.Group>
              ) : null}

              {values.applyRule?.split(',')[1] === 'Placement Bid' ? (
                <>
                  {/*       BidChange Feild       */}
                  <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                    <Form.Label className={styles.formLabel}>Bid Operations</Form.Label>
                    <SelectField
                      name="bidChange"
                      placeholder="Click here to select operation type"
                      values={values}
                      fieldOptions={fieldOptions}
                      options={fieldOptions.updateOperationTypes}
                      setFieldOptions={setFieldOptions}
                      setValues={setValues}
                      setFieldValue={setFieldValue}
                      resetForm={resetForm}
                    />
                  </Form.Group>

                  {/*       Bid Value Feild       */}
                  {values.bidChange.length ? (
                    <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                      <Form.Label className={styles.formLabel}>{`${values.bidChange} bid value ${
                        values.bidChange !== 'Set' ? 'by' : ''
                      }`}</Form.Label>
                      <NumberField name={`bidValue`} step="any" />
                    </Form.Group>
                  ) : null}
                </>
              ) : null}

              <p className={styles.ruleHeading}>
                Your rule will apply to Campaigns that are active at the time the rule runs.
              </p>
              <div className={styles.conditionsMain}>
                <Form.Label className={styles.formLabel}>
                  Conditions <FaInfoCircle />
                </Form.Label>
                <p className={styles.followMatch}>All of the following match</p>
                <div className={styles.addBtn}>
                  {' '}
                  <Button
                    variant="secondary"
                    size="md"
                    onClick={e => addRemoveConditions(e, values, setValues, 'create')}
                  >
                    <LuPlus size={20} />
                  </Button>
                </div>
                {values.conditions.length ? (
                  <DynamicField
                    values={values}
                    fieldOptions={fieldOptions}
                    errors={errors}
                    touched={touched}
                    setValues={setValues}
                    setFieldValue={setFieldValue}
                    addRemoveConditions={addRemoveConditions}
                    setFieldOptions={setFieldOptions}
                  />
                ) : null}
              </div>

              {/*       Time Range Feild       */}
              {values.dataPeriod !== 'Realtime Data' ? (
                <Form.Group className="mb-4 mt-4" controlId="exampleForm.ControlTextarea1">
                  <Form.Label className={styles.formLabel}>
                    Time Range <FaInfoCircle />
                  </Form.Label>
                  <SelectField
                    name="timeRange"
                    placeholder="Click here to select time range"
                    values={values}
                    fieldOptions={fieldOptions}
                    options={fieldOptions.timeRanges}
                    setFieldOptions={setFieldOptions}
                    setValues={setValues}
                    setFieldValue={setFieldValue}
                    resetForm={resetForm}
                  />
                </Form.Group>
              ) : null}

              {/*       Schedule Feild       */}
              <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                <Form.Label className={styles.formLabel}>
                  Schedule <FaInfoCircle />
                </Form.Label>
                <Field as="checkbox" name="schedule">
                  {({ field, form }) => (
                    <Form.Check
                      {...field}
                      type="checkbox"
                      label="Daily"
                      onChange={e => form.handleChange(e)}
                    />
                  )}
                </Field>
              </Form.Group>
              <div>
                {error ? (
                  <Alert key="danger" variant="danger">
                    Something went wrong
                  </Alert>
                ) : null}
              </div>
              <div className={`d-flex justify-content-end ${styles.buttons}`}>
                <div className="me-3">
                  <Button
                    variant="secondary"
                    size="md"
                    className={styles.cancel}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
                <Button type="submit" variant="primary" size="md">
                  Add
                </Button>{' '}
              </div>
            </section>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
}
