import * as Yup from 'yup';

export const initialValues = {
  dataPeriod: 'Historical Data',
  adsCategory: '',
  ruleName: '',
  applyRule: '',
  actionType: '',
  campaignBudget: '',
  bidChange: '', // Select
  bidValue: '', // Field
  conditions: [],
  timeRange: '',
  schedule: false,
};

export const validationSchema = Yup.object().shape({
  dataPeriod: Yup.string().required('Data period is required'),
  adsCategory: Yup.string().when('applyRule', {
    is: key => {
      const value = key?.split(',')[1];
      return value === 'Creatives' || value === 'AdvertiseFSN';
    },
    then: schema => Yup.string().notRequired(),
    otherwise: schema => Yup.string().required('Campaign category is required'),
  }),
  ruleName: Yup.string().required('Rule Name is required'),
  applyRule: Yup.string().required('Rule is required'),
  actionType: Yup.string().when('applyRule', {
    is: key => {
      const value = key?.split(',')[1];
      return value === 'SearchTerm' || value === 'Targeting';
    },
    then: schema => Yup.string().notRequired(),
    otherwise: schema => Yup.string().required('Action is required'),
  }),
  campaignBudget: Yup.string().when('actionType', {
    is: value => value === 'Budget Increase' || value === 'Set Budget Limit',
    then: schema =>
      Yup.string()
        .required('Budget is required')
        .test({
          name: 'budget-lesser-100',
          message: 'Budget must be greater or equal to 100',
          test: function (cpBudgetVal) {
            return cpBudgetVal >= 100;
          },
        }),
    otherwise: schema => Yup.string().notRequired(),
  }),
  bidChange: Yup.string().when('applyRule', {
    is: value => value?.split(',')[1] === 'Placement Bid',
    then: schema => Yup.string().required('BidChange action is required'),
    otherwise: schema => Yup.string().notRequired(),
  }),
  bidValue: Yup.string().when('bidChange', {
    is: value => value !== undefined,
    then: schema =>
      Yup.number().when('bidChange', {
        is: value => value !== 'Set',
        then: schema =>
          Yup.number().integer('Decimal numbers are not allowed').required('Bid value is required'),
        otherwise: schema => Yup.number().required('Bid value is required'),
      }),
    otherwise: schema => Yup.string().notRequired(),
  }),
  conditions: Yup.array().of(
    Yup.object().shape({
      metric: Yup.string().required('Metric is required'),
      condition: Yup.string().required('Condition is required'),
      from_value: Yup.mixed().when('condition', {
        is: inputType => ['Is between', 'Is not between'].includes(inputType),
        then: schema =>
          Yup.object().shape({
            field_2: Yup.number().required('From is required'),
            field_3: Yup.number()
              .required('To is required')
              .test({
                name: 'field_2_greater_than_field_1',
                message: 'From must be less than or equal to To',
                test: function (field_3) {
                  const field_2 = this.parent.field_2;
                  return field_3 >= field_2;
                },
              }),
          }),
        // otherwise: Yup.string().required('Value is required'),
        otherwise: schema =>
          Yup.object().shape({
            field_1: Yup.number().required('From is required'),
          }),
      }),
      relation: Yup.string().required('Relation is required'),
    })
  ),
  timeRange: Yup.string().when('dataPeriod', {
    is: value => value !== 'Realtime Data',
    then: () => Yup.string().required('Schedule is required'),
    otherwise: schema => Yup.string().notRequired(),
  }),
  // schedule: Yup.string().required('Schedule is required'),
});

export const concatArray = arr => {
  return [].concat(...arr);
};

export const parseArray = actionArr => {
  const result = [];
  for (const actionObj of actionArr) {
    let obj = {};
    actionObj.details.forEach(item => {
      obj = { ...obj, ...item };
      obj['type_name'] = actionObj.type_name;
      result.push(obj);
    });
  }
  return result;
};

export const transformedConditions = conditions => {
  const result = [];
  conditions.forEach((condition, index) => {
    result.push({
      metric: condition.metric,
      condition: condition.condition,
      from_value: condition.from_value,
      to: condition.to || null,
    });

    if (index < conditions.length - 1) {
      result.push({ check: condition.relation === 'OR' ? 'OR' : 'AND' });
    }
  });
  return result;
};
