import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';

export default function LogTable() {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_RULES_API}/logs/excecuted`, {
        headers: {
          'ngrok-skip-browser-warning': 'skip',
        },
      })
      .then(({ data }) => {
        setLoading(false);
        setCampaigns(data.campaigns);
      })
      .catch(err => setLoading(false));
  }, []);

  return (
    <>
      <Table stripped bordered hover size="sm">
        <thead>
          <tr>
            <th width="170">Action</th>
            <th width="170">Campaign ID</th>
            <th width="170">Created At</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <span>Loading...</span>
          ) : campaigns?.length ? (
            campaigns.map(campaign => (
              <tr key={campaign._id}>
                <td>{campaign.action_name}</td>
                <td>{campaign.campaign_id}</td>
                <td>{campaign.createdAt ? new Date(campaign.createdAt).toLocaleString() : '-'}</td>
              </tr>
            ))
          ) : (
            <div>No records found</div>
          )}
        </tbody>
      </Table>
    </>
  );
}
